<template>
	<div>
		<CContainer class="d-flex content-center" style="margin-bottom: 2%;">
				<loading-screen v-if="isLoading"></loading-screen>
			<CCard>
				<CCardBody>
					<component v-bind:is="componentName"></component>
				</CCardBody>
			</CCard>
		</CContainer>
	</div>
</template>

<script>
	import functionsForCompany from '../../functions/functionsForCompany';
	import {
		ProcessingAuth,
		FailAuth,
		SuccessAuth,
		CancelAuth,
	} from '../../components/indexComponents';
	export default {
		name: 'Auth',
		title: 'Bienvenido Infoamigo | Infoguia',
		beforeCreate: function() {},
		created() {
			this.companySecurityHash = this.$route.query.state;
			this.$Progress.start();
			if (this.$route.query.error === undefined) {
				this.instagramCode = this.$route.query.code;
				this.componentName = 'ProcessingAuth';
				functionsForCompany
					.getAuthData(this.companySecurityHash, this.instagramCode)
					.then((response) => {
						this.componentName = 'SuccessAuth';
						this.successReplace(response.profileInfoguia);
						this.$Progress.finish();
					})
					.catch((error) => {
						let errorResponse = error.response.data;
						this.process = 'error';
						this.componentName = 'FailAuth';
						this.errorMessage = errorResponse.error;
						this.$Progress.fail();
						this.errorReplace();
					})
					.finally(() => {
						this.isLoading = false;
					});
			} else {
				this.isLoading = true;
				this.dataForPost = {
					errorCode: 409,
					message: this.$route.query.error_description,
					type: this.$route.query.error,
					securityHash: this.companySecurityHash,
					id_statuses: 4,
				};
				functionsForCompany
					.saveError(this.dataForPost)
					.then((response) => {
						this.componentName = 'cancelAuth';
						this.successReplace(response.profileInfoguia);
						this.$Progress.finish();
					})
					.catch((error) => {
						let errorResponse = error.response.data;
						this.process = 'error';
						this.componentName = 'FailAuth';
						this.errorMessage = errorResponse.error;
						this.$Progress.fail();
						this.errorReplace();
					})
					.finally(() => {
						this.isLoading = false;
					});
			}
		},
		data() {
			return {
				instagramCode: '',
				companySecurityHash: '',
				isLoading: false,
				componentName: '',
				errorMessage: '',
				dataForPost: {},
			};
		},
		components: {
			ProcessingAuth,
			SuccessAuth,
			FailAuth,
			CancelAuth,
		},
		methods: {
			errorReplace() {
				setTimeout(function() {
					window.location.replace(
						process.env.VUE_APP_REDIRECT_CONTACT_INFOGUIA
					);
				}, 15000);
			},
			successReplace(urlToReplace) {
				setTimeout(function() {
					window.location.replace(urlToReplace);
				}, 5000);
			},
			replaceToInfoguia() {
				setTimeout(function() {
					window.location.replace(process.env.VUE_APP_INFOGUIA_URL);
				}, 15000);
			},
		},
	};
</script>

<style></style>
