import axiosInfoFeed from '../axiosConfig';
var functionsForCompany = {
	async getCompanyAuthData(securityHash) {
		const response = await axiosInfoFeed.get(
			'api/v1/customers/verify/' + securityHash
		);
		this.response = response.data.data;
		return this.response;
	},
	async getAuthData(securityHash, authcode) {
		const response = await axiosInfoFeed.get(
			'api/v1/customers/' + securityHash + '/authcode/' + authcode
		);
		this.response = response.data.data;
		return this.response;
	},
	async saveError(dataForPost) {
        const response = await axiosInfoFeed.post('api/v1/errorsapiinstagram', dataForPost)
        this.response = response.data.data
        return this.response
    },
	async deleteCustomerData(idCustomer) {
		const response = await axiosInfoFeed.delete(
			'api/v1/customers/' + idCustomer
		);
		this.response = response.data.data;
		return this.response;
	},
};
export default functionsForCompany;
